<!-- 特色活动列表 -->
<template>
    <div class="activity_list">
        <img class="logo" src="../../assets/active/logo2.png" alt="">
        <div class="back flex-align">
            <img src="../../assets/active/back.png" alt="" @click="goBack">
            <span @click="goBack">返回</span>
        </div>
        <div class="content">
            <div class="title_wrap flex-align-around">
                <div class="point"></div>
                <div class="title">首师优字 特色活动</div>
                <div class="point"></div>
            </div>
        </div>
        <div class="item_wrap">
            <template v-if="list.length>0">
                <div class="item" v-for="(item,index) in list" :key="index" @click="goActivity(item.name,item.id)">
                    <img src="../../assets/active/fire.png" alt="" class="fire">
                    <span class="title">{{item.name}}</span>
                    <span class="time">{{item.startAt.split(' ')[0]}}-{{item.endAt.split(' ')[0]}}</span>
                </div>
            </template>
            <el-empty class="empty" description="暂无活动" v-else></el-empty>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                userRole: null,
                list: []
            }
        },
        methods: {
            goBack() {
                // this.$router.go(-1)
                this.$router.push("/home");
            },
            getActivityList() {
                this.$Api.Activity.getActivityList()
                    .then(res => {
                        console.log('活动列表:', res);
                        this.list = res.data
                    })
                    .catch(err => {
                        console.log('活动列表失败:', err)
                    })
            },
            goActivity(activityParentName, activityParentId) {
                this.$router.push({
                    path: "/newactivity",
                    query: {
                        activityParentId,
                        activityParentName
                    }
                });
            }
        },
        mounted() {
            this.getActivityList()
        },
    };
</script>
<style lang='less' scoped>
    @import url("./less/activity_list.less");
</style>